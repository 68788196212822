import ApiService from '@/service'

function processViabilityResponse(response: any): { data: any; selectionBlocks: any } {
	const { data } = response;
	let selectionBlocks = null;
	if (data && data.quotas && data.quotas[0]) {
		selectionBlocks = [...data.quotas[0].selectionBlocks];
	}
	return { data, selectionBlocks };
}

  const state = {
    criteriables:{},
    WatLabPlans:[],
    corporateLogs:[],
    openedStudyTitle:'',
    limits: {},
    platform:"",
  }

  const getters = {
    criteriables(state: any){
      return state.criteriables
    },
    WatLabPlans(state: any){
      return state.WatLabPlans
    },
    corporateLogs(state: any){
      return state.corporateLogs
    },
    openedStudyTitle(state: any){
      return state.openedStudyTitle
    },
    limits(state: any) {
      return state.limits
    },
    studyPlatformType(state: any){
      return state.platform
    },
  }

  const actions = {
    async updateStudyName(context: any, payload: any) {
      return ApiService
        .put(`/clientSuite/study/${payload.contractId}/name`, { title: payload.newName })
        .then(({ data }: any) => {
          return data;
        })
        .catch(({ response }: any) => {
          return Promise.reject(response);
        });
    },

    async checkViability(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchCriteria(context: any, payload:any) {
      return ApiService
      .get(`/category/criteriables/${payload.country}`, null, {headers:{"Accept-Language": "es-ES,es;"}})
        .then(({data}: any) => {
          context.commit('setCriteriables',data)
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATSurvey(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watSurvey', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWATSurvey(context: any, payload: any){
      return ApiService
      .put('/clientSuite/watSurvey/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchTestNavigationRules(context: any, payload: any){
      return ApiService
      .get('/test/' + payload.testId + '/navigationRules', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatSurveyViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watSurvey/' + payload.contractId + '/viability ', null)
      .then(({data}: any) => {
        let selectionBlocks:any;

        if (data.quotas[0]) {
          selectionBlocks = [...data.quotas[0].selectionBlocks];
        }else{
          selectionBlocks = null;
        }
        return {data, selectionBlocks};
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatSurvey(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watSurvey/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async fetchWatLabPlans(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watLab/plansData')
      .then(({data}: any) => {
        context.commit('setWatLabPlans', data.reverse())
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async checkWatLabPrice(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watLab/price', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWatLab(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watLab', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWatLabConfiguration(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watLab/'+payload.contractId+'/configuration', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatLabConfiguration(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watlab/' + payload.contractId + '/configurationData')
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatLab(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watLab/' + payload.contractId + '/confirmation')
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async checkViabilityWatUxQuanti(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUxQuanti/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATUxQuanti(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUxQuanti', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchWatUxQuantiViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watUxQuanti/' + payload.contractId + '/viabilityAndPrice', null)
      .then(processViabilityResponse)
      .catch(({ response }: any) => {
        return Promise.reject(response);
      });
  },
    async saveWATUxQuanti(context:any, payload:any) {
  const tasksToSend = JSON.parse(JSON.stringify(payload.data));
  console.log("saveWATUxQuanti  tasksToSend:", tasksToSend)

  tasksToSend.tasks.forEach((task:any) => {
    if (Array.isArray(task.questions)) {
      task.questions.forEach((question:any) => {
        if (
          question.type === "IMAGE_CHOICE" ||
          question.type === "MULTIPLEIMAGE_CHOICE"
        ) {
          question.imageValues = question.values;
          delete question.values;
        }
      });
    }
  });

  console.log("Resultado final de tasksToSend:", tasksToSend);

  return ApiService
    .put(`/clientSuite/watUxQuanti/${payload.contractId}/step3`, tasksToSend)
    .then(({ data }:any) => data)
    .catch(({ response }:any) => Promise.reject(response));
},
    async confirmationWatUxQuanti(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watUxQuanti/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async checkViabilityWatUx(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUx/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATUx(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUx', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchWatUxViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watUx/' + payload.contractId + '/viabilityAndPrice', null)
      .then(processViabilityResponse)
      .catch(({ response }: any) => {
        return Promise.reject(response);
      });
  },
    async saveWATUx(context: any, payload: any){
      return ApiService
      .put('/clientSuite/watUx/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatUx(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watUx/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async checkViabilityClickTest(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/clickTest/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createClickTest(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/clickTest', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchClickTestViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/clickTest/' + payload.contractId + '/viabilityAndPrice', null)
      .then(processViabilityResponse)
      .catch(({ response }: any) => {
        return Promise.reject(response);
      });
  },
    async saveClickTest(context: any, payload: any){
      return ApiService
      .put('/clientSuite/clickTest/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationClickTest(context: any, payload: any){
      return ApiService
      .post('/clientSuite/clickTest/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async createWatFocus(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watFocus', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatFocusConfiguration(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watFocus/' + payload.contractId + '/configuration', null)
      .then(({data}: any) => {
        context.commit('setOpenedStudyTitle', data.studyName)
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatFocusUserSlots(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watFocus/' + payload.contractId + '/usersSlots', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmTesterWatFocus(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/slottester/' + payload.slotTesterId + "/confirm", null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async completeWatFocusSlot(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/slot/' + payload.slotId, null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWatFocusOrigin(context: any, payload: any){
      payload.data.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/origin', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async saveWatFocusStep3(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/configuration/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async saveWatFocusStep4(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/configuration/step4', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async confirmationWatFocus(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async checkViabilityWatFocus(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watFocus/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchWatFocusViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watFocus/' + payload.contractId + '/viabilityAndPrice', null)
      .then(processViabilityResponse)
      .catch(({ response }: any) => {
        return Promise.reject(response);
      });
  },
    async fetchCorporateLogs(context: any, payload: any){
      const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post(`/clientSuite/corporateLogs/${suitePlanId}/page/${payload.page}/pageSize/${payload.pageSize}`, payload.data)
      .then(({data}: any) => {
        context.commit('setCorporateLogs', data)
        return data;
      })
      .catch(({response}: any) => {
        return response;
      })
    },
    async checkViabilityWatNeuro(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watNeuro/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATNeuro(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watNeuro', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchWatNeuroViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watNeuro/' + payload.contractId + '/viabilityAndPrice', null)
      .then(processViabilityResponse)
      .catch(({ response }: any) => {
        return Promise.reject(response);
      });
  },
    async saveWatNeuro(context: any, payload: any){
      return ApiService
      .put('/clientSuite/watNeuro/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatNeuro(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watNeuro/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async getLimits(context: any, payload: any) {
      const creditsPlanId = context.getters.clientCreditsPlan.identifier
      const url = payload
        ? `/clientSuite/${payload}/${creditsPlanId}/limits`
        : `/clientSuite/${creditsPlanId}/limits`

      return ApiService
        .get(url)
        .then(({data}: any) => {
          context.commit('setLimits', data)
          return data
        })
        .catch(({response}: any)  => {
          return Promise.reject(response.data);
        })
      },
    async createWATUxPublic(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUxPublic', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATUxQuantiPublic(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUxQuantiPublic', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createClickTestPublic(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/clickTestPublic', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    }
  }

  const mutations = {
    setCriteriables(state: any,data: any){
      state.criteriables = data;
    },
    setWatLabPlans(state: any, data: any){
      state.WatLabPlans = data
    },
    setCorporateLogs(state: any, data: any){
      state.corporateLogs = data
    },
    setOpenedStudyTitle(state: any, data: any){
      state.openedStudyTitle = data;
    },
    setLimits(state: any, data: any){
      state.limits = data
    },
    setPlatformType(state: any, data: any){
      state.platform = data;
    }
  }


export default{
  state,
  getters,
  actions,
  mutations
}
