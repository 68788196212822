const ProductTypes = {
    WAT_FOCUS:'WAT_FOCUS',
    WAT_FOCUS_INDEPENDENT:'WAT_FOCUS_INDEPENDENT',
    WAT_UX:'WAT_UX',
    WAT_UX_QUANTI:'WAT_UX_QUANTI',
    WAT_CORPORATE:'WAT_CORPORATE',
    WAT_LAB:'WAT_LAB',
    WAT_SURVEY:'WAT_SURVEY',
    EXPERIENCE_LAB: 'EXPERIENCE_LAB',
    CLICK_TEST: 'CLICK_TEST',
    WAT_NEURO: 'WAT_NEURO'
}

const taskTypes = {
    NEURO_VIDEO:'NEURO_VIDEO'
}

const FocusSubProductTypes = {
    GROUP : 'GROUP',
    INTERVIEW : 'INTERVIEW'
}

const SuiteRoles = {
    SUPERADMIN : 'SUPERADMIN',
    ADMIN: 'ADMIN',
    OBSERVER: 'OBSERVER'
}

const PlanTypes = {
    INTERNAL : 'INTERNAL',
    AD_HOC : 'AD_HOC',
    CREDITS : 'CREDITS',
    MULTI_COMMUNITY : 'MULTI_COMMUNITY',
    LICENSE : 'LICENSE'
}

const userOrigin = {
    WAT : 'WAT',
    CLIENT : 'CLIENT'
}

const deviceTypes = {
    ALL_PLATFORMS : 'ALL_PLATFORMS',
    ONLY_MOBILE : 'ONLY_MOBILE',
    ONLY_DESKTOP : 'ONLY_DESKTOP'
}

export { ProductTypes, SuiteRoles, PlanTypes, userOrigin, FocusSubProductTypes, taskTypes, deviceTypes }