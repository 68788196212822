import ApiService from '@/service'
import i18n from '@/i18n'

  const state = {
    lov:{
      provinces:[],
      regions:[],
      nielsen:[],
      countries:[],
      languages:[]
    },
  }

  const getters = {
    provinces(state: any){
      return state.lov.provinces
    },
    regions(state: any){
      return state.lov.regions
    },
    nielsenAreas(state: any){
      return state.lov.nielsen
    },
    company_sector(state: any){
      return state.lov.company_sector
    },
    countries(state: any){
      return state.lov.countries
    },
    countriesAvailable(state: any){
      return [state.lov.countries[0], state.lov.countries[1]]
    },
    countriesAvailableGeographic(){
      return ["es","pt","mx","ar"]
    },
    languages(state: any){
      return state.lov.languages
    },
    languagesAvailable(state: any){
      return state.lov.languages.filter((lang: any) => lang.secondaryValue.toUpperCase() == 'ES' || lang.secondaryValue.toUpperCase() == 'PT' || 
          lang.secondaryValue.toUpperCase() == 'IT' || lang.secondaryValue.toUpperCase() == 'EN')
    }
  }

  const actions = {
    async getProvinces(context: any, payload:any) {
      return ApiService
      .get('/lovs/provinces/elements/filter/' + payload.country, null)
        .then(({data}: any) => {
          context.commit('setStateLovProvinces',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getRegions(context: any, payload:any) {
      return ApiService
        .get('/lovs/regions/elements/filter/' + payload.country, null)
        .then(({data}: any) => {
          context.commit('setStateLovRegions',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getNielsenAreas(context: any, payload:any) {
      return ApiService
        .get('/lovs/nielsen/elements/filter/' + payload.country, null)
        .then(({data}: any) => {
          context.commit('setStateLovNielsenAreas',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getLovElement(context: any, payload: any){
      return ApiService
        .get('/lovs/'+payload.lovName+'/elements', null)
        .then(({data}: any) => {
          context.commit('setStateLovElements',{data:data, lovName:payload.lovName})
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    }

  }

  const mutations = {
    setStateLovProvinces(state: any,data: any){
      data = data.map(function(value: any){
        value.value = i18n.global.t(value.value)
        return value
      })
      state.lov.provinces = data;
    },
    setStateLovRegions(state: any,data: any){
      data = data.map(function(value: any){
        value.value = i18n.global.t(value.value)
        return value
      })
      state.lov.regions = data;
    },
    setStateLovNielsenAreas(state: any,data: any){
      data = data.map(function(value: any){
        value.value = i18n.global.t(value.value)
        return value
      })
      state.lov.nielsen = data;
    },
    setStateLovElements(state: any,data: any){
      if(data.lovName == 'idiomas') data.lovName = 'languages'
      state.lov[data.lovName] = data.data;
    },
  }

export default{
  actions,
  state,
  getters,
  mutations
}
